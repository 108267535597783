import request from '@/utils/request'

// 查询消息类型列表
export function listMsgType (query) {
  return request({
    url: '/message/msgType/list',
    method: 'get',
    params: query
  })
}

// 查询消息类型详细
export function getMsgType (id) {
  return request({
    url: '/message/msgType/' + id,
    method: 'get'
  })
}

// 新增消息类型
export function addMsgType (data) {
  return request({
    url: '/message/msgType',
    method: 'post',
    data: data
  })
}

// 修改消息类型
export function updateMsgType (data) {
  return request({
    url: '/message/msgType',
    method: 'put',
    data: data
  })
}

// 删除消息类型
export function delMsgType (id) {
  return request({
    url: '/message/msgType/' + id,
    method: 'delete'
  })
}

// 导出消息类型
export function exportMsgType (query) {
  return request({
    url: '/message/msgType/export',
    method: 'get',
    params: query
  })
}
