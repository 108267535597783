<template>
  <vxe-modal
    v-if="open"
    v-model="open"
    width="1200px"
    height="auto"
    :title="formTitle"
    @close="open = false"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="{ top: '10%' }"
    remember
    transfer
    ref="msgTypeModelRef"
  >
    <a-form-model ref="form" :model="form" :rules="rules" class="type-form">
      <a-row :gutter="48">
        <a-col :md="12" :sm="24">
          <a-form-model-item label="类型名称" prop="typeName">
            <a-input v-model="form.typeName" placeholder="请输入类型名称" />
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="类型编码" prop="typeCode">
            <a-input v-model="form.typeCode" placeholder="请输入类型编码" />
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="  类型描述" prop="description">
            <a-input v-model="form.description" placeholder="请输入类型描述" />
          </a-form-model-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-model-item label="状    态" prop="status">
            <a-radio-group v-model="form.status" button-style="solid">
              <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.dictValue)">{{
                d.dictLabel
              }}</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-divider />
      <div class="type-channel-list">
        <div class="title">
          <span>消息类型通道配置</span>
        </div>
        <div class="row">
          <span class="col header">消息组</span>
          <span class="col header">应用</span>
          <span class="col header">消息模板</span>
          <span class="delete-btn header"></span>
        </div>
        <div class="row" v-for="(item, index) in form.typeChannelConfigs" :key="index">
          <type-channel-config-select
            :groupName="item.group ? item.group.groupName : undefined"
            :appName="item.app ? item.app.appName : undefined"
            :appId="item.app ? item.app.id : undefined"
            :selectIndex="index"
            :form="form"
            @handelSelectGroup="handelSelectGroup"
            @handelSelectApp="handelSelectApp"
            @handelSelectTemplate="handelSelectTemplate"
            @handleDelete="handleDelete"
          />
        </div>
      </div>
      <div class="add-btn">
        <a-button :disabled="readOnly" @click="handleAddTypeChannel" type="primary" class="add">
          <a-icon type="plus" />
        </a-button>
      </div>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </vxe-modal>
</template>

<script>
import { getMsgType, addMsgType, updateMsgType } from '@/api/message/msgType'
import TypeChannelConfigSelect from '../components/TypeChannelConfigSelect.vue'
export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    TypeChannelConfigSelect
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        typeName: null,
        description: null,
        typeCode: null,
        createBy: null,
        createTime: null,
        status: 0,
        updateBy: null,
        updateTime: null,
        typeChannelConfigs: [
          {
            groupId: undefined,
            appId: undefined,
            templateId: undefined
          }
        ]
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        typeName: [
          { required: true, message: '消息类型名称不能为空', trigger: 'blur' }
        ],
        typeCode: [
          { required: true, message: '消息类型编码不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleOk () {
      this.open = false
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    handelSelectGroup (value, index) {
      this.form.typeChannelConfigs[index].groupId = value
    },
    handelSelectApp (value, index) {
      this.form.typeChannelConfigs[index].appId = value
    },
    handelSelectTemplate (value, index) {
      this.form.typeChannelConfigs[index].templateId = value
    },
    handleAddTypeChannel () {
      this.form.typeChannelConfigs.push({
        groupId: undefined,
        appId: undefined,
        templateId: undefined
      })
    },
    handleDelete (index) {
      this.form.typeChannelConfigs.splice(index, 1)
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        typeName: null,
        description: null,
        typeCode: null,
        createBy: null,
        createTime: null,
        status: 0,
        updateBy: null,
        updateTime: null,
        typeChannelConfigs: [
          {
            groupId: undefined,
            appId: undefined,
            templateId: undefined
          }
        ]
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加消息类型'
      if (this.form.typeChannelConfigs.length === 0) {
        this.handleAddTypeChannel()
      }
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMsgType(id).then(response => {
        this.form.id = response.data.id
        this.form.typeName = response.data.typeName
        this.form.typeCode = response.data.typeCode
        this.form.description = response.data.description
        this.form.createBy = response.data.createBy
        this.form.createTime = response.data.createTime
        this.form.status = response.data.status
        this.form.updateBy = response.data.updateBy
        this.form.updateTime = response.data.updateTime
        if (response.typeChannelConfigs) {
          this.form.typeChannelConfigs = response.typeChannelConfigs
        } else {
          if (this.form.typeChannelConfigs.length === 0) {
            this.handleAddTypeChannel()
          }
        }
        this.open = true
        this.formTitle = '修改消息类型'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log('this.form >>> ', this.form)
          // return false
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMsgType(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addMsgType(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.type-form {
  .ant-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    .ant-form-item-label {
      flex: 0 0 80px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
      widows: 90%;
    }
  }
  .input-remark {
    margin-left: 10px;
  }
  .area-color {
    width: 25px;
    height: 25px;
    border: 1px solid black;
  }
  .bottom-control {
    border-top: 0;
    padding-top: 30px;
  }
}

.type-channel-list {
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .row {
    display: flex;
    .item-delete {
      font-size: 20px;
    }
    .col {
      flex: 1;
      text-align: center;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
    .header {
      height: 30px;
      line-height: 30px;
      padding: 0 2px;
    }
    .col.header::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .col:first-child {
      flex: 1;
      min-width: 280px;
    }
    .delete-btn {
      flex: 0 0 30px;
      border: 0;
      border-left: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add {
      width: 100%;
    }
    .ant-input-number {
      border-radius: 0;
      width: 100%;
      border: 0;
    }
    input {
      border-radius: 0;
      text-align: center;
    }
    .ant-time-picker {
      border: 0;
    }
    .ant-time-picker-input {
      border: 0 !important;
    }
    .connect-flag {
      border: 0;
      width: 5px;
    }
  }
  .row:last-child {
    .col {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
.add-btn {
  width: 100%;
  margin-top: 20px;
  margin-right: 30px;
  button {
    width: 100%;
  }
}
</style>
