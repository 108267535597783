var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.open
    ? _c(
        "vxe-modal",
        {
          ref: "msgTypeModelRef",
          attrs: {
            width: "1200px",
            height: "auto",
            title: _vm.formTitle,
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: false,
            "esc-closable": true,
            position: { top: "10%" },
            remember: "",
            transfer: "",
          },
          on: {
            close: function ($event) {
              _vm.open = false
            },
          },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              staticClass: "type-form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "类型名称", prop: "typeName" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入类型名称" },
                            model: {
                              value: _vm.form.typeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "typeName", $$v)
                              },
                              expression: "form.typeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "类型编码", prop: "typeCode" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入类型编码" },
                            model: {
                              value: _vm.form.typeCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "typeCode", $$v)
                              },
                              expression: "form.typeCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "  类型描述", prop: "description" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入类型描述" },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "状    态", prop: "status" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: { "button-style": "solid" },
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (d, index) {
                              return _c(
                                "a-radio-button",
                                {
                                  key: index,
                                  attrs: { value: parseInt(d.dictValue) },
                                },
                                [_vm._v(_vm._s(d.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-divider"),
              _c(
                "div",
                { staticClass: "type-channel-list" },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [_vm._v("消息类型通道配置")]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("span", { staticClass: "col header" }, [
                      _vm._v("消息组"),
                    ]),
                    _c("span", { staticClass: "col header" }, [_vm._v("应用")]),
                    _c("span", { staticClass: "col header" }, [
                      _vm._v("消息模板"),
                    ]),
                    _c("span", { staticClass: "delete-btn header" }),
                  ]),
                  _vm._l(_vm.form.typeChannelConfigs, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "row" },
                      [
                        _c("type-channel-config-select", {
                          attrs: {
                            groupName: item.group
                              ? item.group.groupName
                              : undefined,
                            appName: item.app ? item.app.appName : undefined,
                            appId: item.app ? item.app.id : undefined,
                            selectIndex: index,
                            form: _vm.form,
                          },
                          on: {
                            handelSelectGroup: _vm.handelSelectGroup,
                            handelSelectApp: _vm.handelSelectApp,
                            handelSelectTemplate: _vm.handelSelectTemplate,
                            handleDelete: _vm.handleDelete,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "add-btn" },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "add",
                      attrs: { disabled: _vm.readOnly, type: "primary" },
                      on: { click: _vm.handleAddTypeChannel },
                    },
                    [_c("a-icon", { attrs: { type: "plus" } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }